.container {
    max-width: fit-content;
    max-width: -moz-fit-content;
    margin: 0 auto;
    padding: 1.5rem;
    background-color: #edae00fa;
    /*background-image: url(/img/bg-5.png);*/
    
}


.menu {
    font-family: sans-serif;
    font-size: 14px;
}
.logo {
    text-align: center;
}
@font-face {
    font-family: HEINEKEN;
    src: url(../src/public/css/HEINEKEN.TTF);
}

h2 {
    font-family: HEINEKEN;
}


.menu-group-heading {
    margin: 0;
    padding-bottom: 1em;
    border-bottom: 2.5px solid #41280f83;
    font-weight: lighter;
    font-size: 25px;
    font-family: fantasy, HEINEKEN,'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #0c0900b0;
    text-align: center;
}

.menu-group {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5em;
    padding: 1.5em 0;
}

.menu-item {
    display: flex;
    /*display: relative;*/
    padding: 1.5rem;

}

.menu-item-image {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    object-fit: contain;
    margin-right: 1.5em;
    border-radius: 2px;
}

.menu-item-text {
    flex-grow: 1;
}

.menu-item-heading {
    display: flex;
    justify-content: space-between;
    margin: 0;
    color:rgba(58, 45, 45, 0.821)
}

.menu-item-name {
    margin-right: 1.5em;
}

.menu-item-description {
    line-height: 1.5;
    margin-top: 1.1rem;
}

p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: rgba(4, 19, 1, 0.664);
}

/*
@font-face {
    font-family: HEINEKEN;
    src: url(../src/public/css/HEINEKEN.TTF);
}
h2 {
    font-family: HEINEKEN;
}
*/

@media screen and (min-width: 992px) {
    .menu {
        font-size: 16px;
    }

    .menu-group {
        grid-template-columns: repeat(2, 1fr);
    }

    .menu-item-image {
        width: 125px;
        height: 125px;
    }
}
/*
@media screen and (min-width: 320px) {
    body {
        min-width: 320px;
    }
}
*/

